@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,700;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Roboto Mono','Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  font-family: 'cursive'
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Don't know how to do these pseudoelements in tailwind */
input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  background: #FFF;
  box-shadow: -405px 0 0 400px #2B4B62;
  border-radius: 50%;
}

input[type="range"] {
  background-color: rgb(190, 200, 207);
  overflow: hidden;
  appearance: none;
  border-radius: .5rem;
}

input[type="range"]:focus {
  outline: none;
}

.kmeansgraph {
  position: relative;
  width: 60%;
  height: 50%;
  float: right;
}

.axis-selector button {
  border: none;
  outline: none;
  padding: 7px 10px;
  margin: 1px;
  background-color: #8D9DBA;
  font-weight: bold;
  font-size: 12pt;
  color: #f2f2f2;
}

.axis-selector button:hover {
  background-color: #617394;
}

.axis-selector button.selected {
  background-color: #394D73;
}

.min-w-20vw {
  min-width: 20vw;
}

.max-w-20vw {
  max-width: 20vw;
}

.max-w-60vw {
  max-width: 60vw;
}

.w-20vw {
  width: 20vw;
}

.w-60vw {
  width: 60vw;
}

.md60vw-sm20vw {
  width: 60vw;
}

@media (min-width:768px) {
  .md60vw-sm20vw {
    width: 20vw;
  }
}


.md30vw-sm60vw {
  width: 60vw;
}

@media (min-width:768px) {
  .md30vw-sm60vw {
    width: 30vw;
  }
}


.img-invert {
  filter: invert(1)
}

.mr-half {
  margin-right: 0.125rem;
}